import { UserTaskCardProps, UserTaskCard } from './UserTaskCard';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TaskGroupType } from 'shared/types/misc';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValueLoadable } from 'recoil';
import { userServiceFeeSelector } from 'services/user/recoil';
import {
  CONSUMER_SERVICE_FEE_DISCOUNTED_CENTS,
  getConsumerPaymentString,
} from 'shared/utils/constants';

const Container = styled(Paper)(
  ({ theme }) => `
  &.MuiPaper-root {
    padding: ${theme.spacing(2)};
  }`,
);

const BoldPreWrap = styled('b')(
  () => `
  white-space: pre-wrap;`,
);

export type TaskGroupProps = {
  title: TaskGroupType['name'];
  tasks: Array<UserTaskCardProps>;
};

export const TaskGroup: FC<TaskGroupProps> = ({ title, tasks }) => {
  const userServiceFee = useRecoilValueLoadable(userServiceFeeSelector);
  const showServiceFeeDiscount =
    title == 'Guarantee your deal' &&
    userServiceFee.valueMaybe() === CONSUMER_SERVICE_FEE_DISCOUNTED_CENTS;

  return (
    <Container variant='outlined'>
      <Typography variant='h6' color='textPrimary'>
        <BoldPreWrap>{title}</BoldPreWrap>
      </Typography>
      {showServiceFeeDiscount && (
        <Typography
          variant='subtitle2'
          color='white'
          maxWidth={'300px'}
          sx={{
            backgroundColor: '#f22f2f',
            padding: '0 10px',
            borderRadius: '4px',
          }}
        >
          Your AB service fee is discounted: <s>{getConsumerPaymentString()}</s>{' '}
          {getConsumerPaymentString(CONSUMER_SERVICE_FEE_DISCOUNTED_CENTS)}
        </Typography>
      )}
      {tasks.map((task, index) => (
        <Box mt={2} key={index}>
          <UserTaskCard {...task} />
        </Box>
      ))}
    </Container>
  );
};
