import { datadogRum } from '@datadog/browser-rum';
import {
  isAdminSelector,
  isAuthorizedSelector,
  isConsumerSelector,
  isDealerSelector,
  isSalesSelector,
} from 'containers/deal/recoil/selectors';
import isEmpty from 'lodash/isEmpty';
import { selector, selectorFamily } from 'recoil';
import { auth } from 'services/auth';
import Rest from 'services/rest';
import { getAuctionCustomRebates } from 'shared/incentives/getAuctionRebates';
import {
  GetAuctionPaymentAnalyticsRequest,
  GetAuctionPaymentAnalyticsResponse,
} from 'shared/types/api/getAuctionPaymentAnalytics';
import {
  GetAuctionsRequest,
  GetAuctionsResponse,
} from 'shared/types/api/getAuctions';
import { GetConsumerDocumentsParams } from 'shared/types/api/getConsumerDocuments';
import { GetCosignerDocumentsParams } from 'shared/types/api/getCosignerDocuments';
import {
  GetOrCreateVerificationSessionRequest,
  GetOrCreateVerificationSessionResponse,
} from 'shared/types/api/getOrCreateVerificationSession';
import {
  GetPopularMakes,
  GetPopularMakesResponse,
} from 'shared/types/api/getPopularMakes';
import {
  DueAtSigningType,
  GetProgramRequest,
  GetProgramResponse,
} from 'shared/types/api/getProgram';
import {
  GetRebatesParamsRequest,
  GetRebatesParamsResponse,
} from 'shared/types/api/getRebatesParams';
import { DealTypeEnum } from 'shared/types/endpoints';
import {
  MSAPIMake,
  MSAPIModel,
  MSAPIRequestRebateCategory,
  MSAPIScanMode,
  MSISRebate,
} from 'shared/types/marketScan';
import {
  Auction,
  Offer,
  Rebate,
  TierScore,
  TierScoreValueMap,
  User,
  UserVehicle,
} from 'shared/types/models';
import { BEST_TERM_LENGTH } from 'shared/utils/constants';
import { getProgramRequestFromProgram } from 'shared/utils/getProgramRequestFromProgram';
import {
  userClientRebatesCategoriesAnswersAtom,
  userClientVehicleOwnAtom,
} from '../components/RebatesCategoriesDialog';
import {
  auctionAtom,
  creditScoreInputAtom,
  dueAtSigningTypeInputSelectorFamily,
  milesInputAtom,
  quoteAtom,
  scanModeInputAtom,
} from '../containers/deal/recoil/atoms';
import { filterZipcodeAtom } from '../services/zipcode/recoil';
import {
  GetRebateBriefDescriptionRequest,
  GetRebateBriefDescriptionResponse,
} from '../shared/types/api/getRebateBriefDescription';
import { RebateCategoryAnswer } from '../shared/types/common';
import {
  defaultRebatesCategoriesAnswers,
  evaluateProgramZipcode,
  getUserCreditScore,
} from '../shared/utils/functions';
import {
  msMakesAtom,
  msModelsAtom,
  rebatesAtom,
  rebatesCategoriesAtom,
  userAtom,
  userClientRebatesNamesAtom,
  userWinningBidIdAtom,
  visitedAuctionsIDs,
} from './atoms';
import { SAFE_RECOIL_CACHE } from './safeRecoilCache';
import { guardRecoilDefaultValue } from './utils/functions';

type ProgramFromProgramSelectorType = {
  baseProgram: GetProgramResponse;
  overwriteParams: Partial<GetProgramRequest> & {
    salesPrice?: number;
    monthlyPayment?: number;
  };
  id: string;
  auctionId: number;
};

const NORMAL_PROGRAM_PARAMS = {
  creditScore: TierScoreValueMap[TierScore.SuperElite],
  downPayment: undefined,
  miles: 10000,
  mo: BEST_TERM_LENGTH,
  msd: 0,
  isOwnersChoice: false,
  waiveTheAcquisitionFee: false,
  onePayLease: false,
  upfrontAcquisitionFee: true,
  zeroDriveOff: false,
  dueAtSigningType: DueAtSigningType.FIRST_MONTHLY_ALL_FEES_SALES_TAX,
};

function isNormalProgramParams(params: GetProgramRequest) {
  return (
    params.zipcode === params.dealerZipcode &&
    Object.keys(NORMAL_PROGRAM_PARAMS).every(
      (key) => NORMAL_PROGRAM_PARAMS[key] === params[key],
    )
  );
}

export type AuctionProgramSelectorType = {
  params: Omit<
    GetProgramRequest,
    'vehicleId' | 'zipcode' | 'msrp' | 'demoMileage' | 'invoicePrice'
  > & { zipcode?: string };
  auction: {
    msrp: Auction['msrp'];
    invoicePrice: Auction['invoice_price'];
    offer: {
      mileage: Offer['mileage'];
      zip: Offer['zip'];
      vehicleId: Offer['vehicle_id'];
    };
  };
  user: User;
};

const rest = new Rest();

/**
 * This is the most low level selector for lease program.
 * As ussual you're are not gonna use it directly from components.
 * It's better to wrap it by another selectors like
 * "programFromProgramSelector", "auctionProgramSelector" to
 * simplify selector interface
 */
export const programSelector = selectorFamily<
  GetProgramResponse,
  GetProgramRequest
>({
  key: 'PROGRAM_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    (params) =>
    async ({ get }) => {
      if (!params.monthlyPayment && !params.salesPrice) {
        return;
      }

      if (params.monthlyPayment && params.monthlyPayment < 100) {
        // to avoid useless queries when user typing payment amoount
        return;
      }
      const quote = get(quoteAtom);

      const rebatesCategoriesParam = !quote
        ? get(getRebatesCategoriesParams(params.rebatesCategories))
        : {};

      const quoteScanMode = quote?.program.FullDetails.Lease
        ? MSAPIScanMode.Lease
        : MSAPIScanMode.Retail;
      const scanMode = !quote ? get(scanModeInputAtom) : quoteScanMode;

      const programParams = {
        ...params,
        ...rebatesCategoriesParam,
        scanMode,
      };
      try {
        const res = await rest.getProgram(programParams);

        return res;
      } catch (err) {
        if (isNormalProgramParams(programParams)) {
          datadogRum.addError({ err, programParams });
        }

        throw err;
      }
    },
});

export const programsSelector = selectorFamily<
  GetProgramResponse[],
  GetProgramRequest
>({
  key: 'PROGRAM_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    (params) =>
    async ({ get }) => {
      if (!params.monthlyPayment && !params.salesPrice) {
        return;
      }

      if (params.monthlyPayment && params.monthlyPayment < 100) {
        // to avoid useless queries when user typing payment amoount
        return;
      }
      const quote = get(quoteAtom);

      const rebatesCategoriesParam = !quote
        ? get(getRebatesCategoriesParams(params.rebatesCategories))
        : {};

      const programParams = { ...params, ...rebatesCategoriesParam };
      try {
        const res = await rest.getPrograms(programParams);

        return res;
      } catch (err) {
        if (isNormalProgramParams(programParams)) {
          datadogRum.addError({ err, programParams });
        }

        throw err;
      }
    },
});

export const auctionPaymentAnalyticsSelector =
  selector<GetAuctionPaymentAnalyticsResponse>({
    key: 'AUCTION_PAYMENT_ANALYTICS_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: async ({ get }) => {
      const auction = get(auctionAtom);
      if (!auction) {
        return [];
      }
      const user = get(userAtom);

      const dueAtSigningType = get(
        dueAtSigningTypeInputSelectorFamily(auction.id),
      );
      const creditScore = getUserCreditScore(user, get(creditScoreInputAtom));
      const dealType =
        get(scanModeInputAtom) === MSAPIScanMode.Lease
          ? DealTypeEnum.LEASE
          : DealTypeEnum.FINANCE;

      const miles = get(milesInputAtom);

      const params = {
        auctionId: auction.id,
        dealType,
        creditScore,
        miles,
        dueAtSigningType,
      };

      return get(paymentAnalyticsSelector(params));
    },
  });

const paymentAnalyticsSelector = selectorFamily<
  GetAuctionPaymentAnalyticsResponse,
  GetAuctionPaymentAnalyticsRequest
>({
  key: 'PAYMENT_ANALYTICS_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    return rest.getAuctionPaymentAnalytics(params);
  },
});

export const getRebatesCategoriesParams = selectorFamily<
  { rebatesCategories?: MSAPIRequestRebateCategory[] },
  MSAPIRequestRebateCategory[]
>({
  key: 'REBATES_PARAMS',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    (rebatesCategories) =>
    ({ get }) => {
      const rebatesCategoriesSelector = get(rebatesCategoriesAtom);
      const rebatesCategoriesParam = rebatesCategories
        ? { ...(rebatesCategories.length > 0 ? { rebatesCategories } : {}) }
        : { rebatesCategories: rebatesCategoriesSelector };

      return rebatesCategoriesParam;
    },
});

export const rebatesParamsSelector = selectorFamily<
  GetRebatesParamsResponse,
  GetRebatesParamsRequest
>({
  key: 'REBATES_PARAMS_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    if (!params.auctionId) {
      return;
    }

    try {
      return await rest.getRebatesParams(params);
    } catch (err) {
      return;
    }
  },
});

type AuctionRebatesSelectorOverwriteSettings = {
  zipcode?: string;
};

export const auctionRebatesSelector = selectorFamily<
  MSISRebate[],
  {
    auction: Auction;
    id?: string;
    overwrite?: AuctionRebatesSelectorOverwriteSettings;
  }
>({
  key: 'AUCTION_REBATES_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    ({ auction, overwrite }) =>
    async ({ get }) => {
      if (!auction) {
        return [];
      }
      const quote = get(quoteAtom);
      const isDealer = get(isDealerSelector);
      const userZipcode = get(userAtom)?.zipcode;
      const filterZipcode = get(filterZipcodeAtom);
      const dealerZipcode = auction.offer.zip[0];

      const requestZipcode = !quote
        ? evaluateProgramZipcode({
            isDealer,
            userZipcode,
            dealerZipcode,
            filterZipcode,
          })
        : userZipcode || quote.program.ZIP;
      const vehicleOwn = get(userVehicleOwnSelector);
      const makes = get(msMakesAtom);
      const models = get(msModelsAtom);

      const customRebates = getAuctionCustomRebates(auction);

      const localRebates =
        get(
          rebatesParamsSelector({
            auctionId: auction.id,
            zipcode: overwrite?.zipcode || requestZipcode,
            vehicleOwn: vehicleOwn
              ? {
                  Item: vehicleOwn.map((vehicle) => ({
                    Make: getMakeOrModelNameById(
                      makes,
                      vehicle.market_scan_make_id,
                    ),
                    Model: getMakeOrModelNameById(
                      models,
                      vehicle.market_scan_model_id,
                    ),
                    Year: String(vehicle.year),
                  })),
                }
              : undefined,
          }),
        )?.Rebates || [];

      return localRebates.concat(customRebates);
    },
});

/**
 * This selector is designed to fetch the program based of another program
 * pass your program as baseProgram and define parameters that differ as overwriteParams
 */
export const programFromProgramSelector = selectorFamily<
  GetProgramResponse,
  ProgramFromProgramSelectorType
>({
  key: 'PROGRAM_FROM_PROGRAM_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    ({ baseProgram, overwriteParams, auctionId }) =>
    async ({ get }) => {
      if (!baseProgram || !auctionId) {
        return;
      }

      const user = get(userAtom);

      const requestZipcode = evaluateProgramZipcode({
        isDealer: get(isDealerSelector),
        userZipcode: user?.zipcode,
        dealerZipcode: get(auctionAtom)?.offer.zip[0],
        filterZipcode: get(filterZipcodeAtom),
      });

      const params = getProgramRequestFromProgram({
        program: baseProgram,
        auctionId,
        params: {
          creditScore:
            overwriteParams?.creditScore ||
            user?.cosigners?.[0]?.credit_score ||
            user?.credit_score,
          zipcode: requestZipcode || overwriteParams?.zipcode,
        },
      });
      delete overwriteParams.zipcode;

      const rebatesCategories = params?.rebatesCategories;

      return get(
        programSelector({
          ...params,
          ...overwriteParams,
          salesPrice: overwriteParams.monthlyPayment
            ? null
            : overwriteParams.salesPrice,
          monthlyPayment: overwriteParams.salesPrice
            ? null
            : overwriteParams.monthlyPayment,
          dueAtSigningType: get(dueAtSigningTypeInputSelectorFamily(auctionId)),
          rebatesCategories: rebatesCategories ?? [],
        }),
      );
    },
});

/**
 * This selector is returning a program for a specific auction.
 * You still need to specify a lot of params manually but some parameters
 * will be taken from auction: msrp, zipcode, vehicleId etc
 */
export const auctionProgramsSelector = selectorFamily<
  GetProgramResponse[],
  AuctionProgramSelectorType
>({
  key: 'AUCTION_PROGRAM_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    ({ params, auction, user }) =>
    async ({ get }) => {
      const demoMileage = auction?.offer.mileage;
      const msrp = auction?.msrp;
      const vehicleId = auction?.offer.vehicleId;
      const dealerZipcode = auction?.offer.zip[0];
      const invoicePrice = auction?.invoicePrice;
      const zipcode =
        params.zipcode ||
        evaluateProgramZipcode({
          isDealer: get(isDealerSelector),
          userZipcode: user?.zipcode,
          dealerZipcode,
          filterZipcode: get(filterZipcodeAtom),
        });

      return get(
        programsSelector({
          demoMileage,
          msrp,
          invoicePrice,
          vehicleId,
          zipcode,
          dealerZipcode,
          ...params,
        }),
      );
    },
});

/**
 * Returns an exsisting verification session if it exists, otherwise creates
 * a new one.
 */
export const sessionSelector = selectorFamily<
  GetOrCreateVerificationSessionResponse,
  GetOrCreateVerificationSessionRequest
>({
  key: 'SESSION_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get:
    (request) =>
    ({ get }) => {
      const isConsumer = get(isConsumerSelector);
      const isAuthorized = get(isAuthorizedSelector);

      if (!isConsumer || !isAuthorized) {
        return;
      }

      return rest.getOrCreateVerificationSession(request);
    },
});

type ConsumerDocument = {
  id: string;
  file: Blob;
};

function isGetConsumerDocumentsParams(
  params: GetConsumerDocumentsParams | GetCosignerDocumentsParams,
): params is GetConsumerDocumentsParams {
  return (params as GetConsumerDocumentsParams).consumerId !== undefined;
}

/** Loads list of documents and pre-fetches the document files. */
export const documentsSelector = selectorFamily<
  ConsumerDocument[],
  GetConsumerDocumentsParams | GetCosignerDocumentsParams
>({
  key: 'DOCUMENTS_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    let documents = [];
    let files = [];
    if (isGetConsumerDocumentsParams(params)) {
      documents = await rest.getConsumerDocuments(params);
      files = await Promise.all(
        documents.map(async (doc) => {
          const response = await rest.streamConsumerDocument({
            ...params,
            documentId: doc.id,
          });
          return response.blob();
        }),
      );
    } else {
      documents = await rest.getCosignerDocuments(params);
      files = await Promise.all(
        documents.map(async (doc) => {
          const response = await rest.streamCosignerDocument({
            ...params,
            documentId: doc.id,
          });
          return response.blob();
        }),
      );
    }

    return documents.map((doc, i) => ({
      ...doc,
      file: files[i],
    }));
  },
});

/**
 * This selector returning rebates user has selected.
 * It is working for authorazied and non authorized users.
 */
export const userRebatesNamesSelector = selector<string[]>({
  key: 'USER_REBATES_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user) {
      return get(userClientRebatesNamesAtom) || [];
    }

    return user.rebates_names || [];
  },
});

/**
 * This selector returning market scan rebates user has selected for specific auction.
 * It is working for authorazied and non authorized users.
 */
export const userAuctionRebatesSelector = selectorFamily<MSISRebate[], Auction>(
  {
    key: 'USER_AUCTION_REBATES_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get:
      (auction) =>
      ({ get }) => {
        const userRebatesNames = get(userRebatesNamesSelector);
        const quoteRebateNames = get(quoteAtom)?.program.AppliedRebate?.map(
          (r) => r.Name,
        );
        const auctionRebates = get(
          auctionRebatesSelector({
            auction,
          }),
        );

        if (quoteRebateNames) {
          return auctionRebates.filter((rebate) => {
            return quoteRebateNames.includes(rebate.Name);
          });
        }

        return auctionRebates.filter((rebate) => {
          // Include `Selected` rebates as they must be included in
          // the get program request to check if it can be applied
          return userRebatesNames.includes(rebate.Name) || rebate.Selected;
        });
      },
  },
);

export const rebatesSelector = selector<Rebate[]>({
  key: 'REBATES_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => get(rebatesAtom) || rest.getRebates(),
  set: ({ set }, updatedRebates) => {
    set(rebatesAtom, updatedRebates);
  },
});

export const userRebatesCategoriesAnswersSelector = selector<
  RebateCategoryAnswer[]
>({
  key: 'USER_INCENTIVE_ELIGIBILITY_ANSWERS',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => {
    const user = get(userAtom);
    const isSales = get(isSalesSelector);
    const isAdmin = get(isAdminSelector);
    const isDealer = get(isDealerSelector);
    if (isSales || isAdmin || isDealer) {
      return defaultRebatesCategoriesAnswers;
    }
    return isEmpty(user)
      ? get(userClientRebatesCategoriesAnswersAtom)
      : user.rebates_categories_answers;
  },
});

export const userVehicleOwnSelector = selector<UserVehicle[]>({
  key: 'USER_VEHICLE_OWN',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => {
    const isConsumer = get(isConsumerSelector);
    const user = get(userAtom);

    if (user && !isConsumer) {
      return [];
    }

    return isEmpty(user)
      ? get(userClientVehicleOwnAtom)
      : rest.getUserVehicles();
  },
});

export const getUserWinningBid = selector({
  key: 'USER_WINNING_BID',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: async ({ get }) => {
    const bid = await rest.getUserWinningBid(get(userWinningBidIdAtom));
    return bid;
  },
});

/** Checks if user's phone number in Cognito is verified. */
export const isPhoneNumberVerifiedSelector = selector<boolean>({
  key: 'IS_PHONE_NUMBER_VERIFIED',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: () => auth.isPhoneNumberVerified(),
});

export const rebateBriefDescriptionSelector = selectorFamily<
  GetRebateBriefDescriptionResponse,
  GetRebateBriefDescriptionRequest
>({
  key: 'REBATE_BRIEF_DESC_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    if (!params.auctionId || !params.rebateId) {
      return;
    }

    const description = await rest.getRebateBriefDescription(params);

    if (!description) {
      throw new Error('Rebate brief description was not loaded');
    }

    return description;
  },
});

const getMakeOrModelNameById = (
  modelsOrMakes: Array<MSAPIMake | MSAPIModel> = [],
  id: number,
): string | undefined => {
  const modelOrMake = modelsOrMakes.find((element) => element.ID === id);
  if (!modelOrMake) {
    return undefined;
  }
  return modelOrMake.Name.replace(/_/g, ' ');
};

export const auctionVisitSelector = selector<number[] /* IDs */>({
  key: 'AUCTION_VISIT_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => get(visitedAuctionsIDs),
  set: ({ set, reset }, auctionsIDs) => {
    if (guardRecoilDefaultValue(auctionsIDs)) {
      reset(visitedAuctionsIDs);
      return;
    }
    set(visitedAuctionsIDs, auctionsIDs);
  },
});

export const popularAuctionsSelector = selectorFamily<
  GetAuctionsResponse,
  GetAuctionsRequest
>({
  key: 'POPULAR_AUCTIONS_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    if (!params.sortType || !params.perPage) {
      return;
    }

    const auctions = await rest.getAuctions(params);

    if (!auctions) {
      throw new Error('Popular auctions were not loaded');
    }

    return auctions;
  },
});

export const popularMakesSelector = selectorFamily<
  GetPopularMakesResponse,
  GetPopularMakes
>({
  key: 'POPULAR_MAKES_SELECTOR',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (params) => async () => {
    if (!params.perPage || !params.daysInterval) {
      return;
    }

    const makes = await rest.getPopularMakes(params);

    if (!makes) {
      throw new Error('Popular makes were not loaded');
    }

    return makes;
  },
});
