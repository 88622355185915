import { TierNames, TierScore, TierScoreValueMap } from '../types/models';
import { Makes } from './makes';

export enum REJECTION_REASONS {
  OTHER = 'Other',
  NO_VEHICLE = 'Vehicle no longer in inventory',
  BUYER_NOT_QUALIFIED = 'Buyer does not qualify',
  DEAL_COMPLETION_TIME_EXPIRED = 'Time to finalise the deal expired',
  TECHNICAL_AUTO_DISQUALIFY = 'Technical auto-disqualified',
}

/**
 * This is the map of possible environments. This map is shared between frontend and backend.
 *
 * On the frontend side we keep this value under process.env.ENVIRONMENT variable
 * because NODE_ENV is reserved by NextJS.
 *
 * NODE_ENV is always "production" when we are building app by `next build`
 * And it is always "development" when we are running app locally by `next dev`
 *
 * On the backend side we keep this value under process.env.NODE_ENV
 */
export enum ENVIRONMENT {
  'PRODUCTION' = 'production', // leasebandit.com
  'SANDBOX' = 'sandbox', // demo.leasebandit.com *.leasebandit.com.xyz
  'E2E' = 'e2e', // puppeter e2e tests
  'DEVELOPMENT' = 'development', // localhost
  'TEST' = 'test', // backend api tests
}

export enum BRAND_NAME_ENUM {
  'LEASEBANDIT' = 'Lease Bandit',
  'AUTOBANDIT' = 'AutoBandit',
}

export enum DOMAIN_NAME_ENUM {
  'LEASEBANDIT' = 'leasebandit.com',
  'AUTOBANDIT' = 'autobandit.com',
}

export const SUPPORTED_MAKES = Object.values(Makes);

export type CreditTier = {
  name: TierNames;
  score: { min: number; max: number };
  avgScore: number;
  isCosignerRequired: boolean;
};

export const SKIP_FAIR_CREDIT_TIER_COSIGNER = true;

export const CREDIT_TIERS: CreditTier[] = [
  {
    name: TierNames.SuperElite,
    score: { min: 740, max: 999 },
    avgScore: TierScoreValueMap[TierScore.SuperElite],
    isCosignerRequired: false,
  },
  {
    name: TierNames.Elite,
    score: { min: 700, max: 739 },
    avgScore: TierScoreValueMap[TierScore.Elite],
    isCosignerRequired: false,
  },
  {
    name: TierNames.Standard,
    score: { min: 675, max: 699 },
    avgScore: TierScoreValueMap[TierScore.Standard],
    isCosignerRequired: false,
  },
  {
    name: TierNames.StandardPlus,
    score: { min: 640, max: 674 },
    avgScore: TierScoreValueMap[TierScore.StandardPlus],
    isCosignerRequired: false,
  },
  {
    name: TierNames.Progressive,
    score: { min: 601, max: 639 },
    avgScore: TierScoreValueMap[TierScore.Progressive],
    isCosignerRequired: false,
  },
  {
    name: TierNames.Fair,
    score: { min: 0, max: 600 },
    avgScore: TierScoreValueMap[TierScore.Fair],
    isCosignerRequired: !SKIP_FAIR_CREDIT_TIER_COSIGNER,
  },
];

export const LOWEST_TIER_SCORE = '0-600';

export const AVAILABLE_IN_STATES = ['NJ', 'NY', 'PA', 'CT', 'MD', 'CA', 'FL'];
export const MINIMAL_SEARCH_DISTANCE = 50;
export const DEFAULT_SEARCH_DISTANCE = 200;
export const MAXIMUM_SEARCH_DISTANCE = 2000;
