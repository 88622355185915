import { styled as styledMui5 } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { createContext, FC, useEffect, useRef } from 'react';
import { defaultFallbackInView, useInView } from 'react-intersection-observer';
import { AuctionStatus, Bid, BidStatus, Permission } from 'shared/types/models';

import Footer, { FooterLink } from 'components/organisms/Footer';
import { IRunnerUpDialog } from 'components/organisms/RunnerUpDialog';
import { RunnerUpDialogDynamic as RunnerUpDialog } from 'components/organisms/RunnerUpDialogDynamic';
import TopNav from 'components/organisms/TopNav';

import Auth, { auth } from '../../services/auth';

import { NoSSR } from 'components/atoms/NoSSR';
import { TopNavMenuItemProps } from 'components/atoms/TopNav';
import { ForwardToDialog } from 'components/organisms/ForwardToDialog';
import { PreventMultipleWinningBidDialog } from 'components/organisms/PreventMultipleWinningBidDialog';
import { SelectAuctionRebatesDialog } from 'components/organisms/SelectAuctionRebates/SelectAuctionRebatesDialog';
import { rebatesCustomZipcodeAtom } from 'containers/deal/recoil/atoms';
import {
  isAdminSelector,
  isDealerSelector,
} from 'containers/deal/recoil/selectors';
import { getTabs } from 'containers/profile/getTabs';
import { useScreenSize } from 'hooks/useScreenSize';
import kebabCase from 'lodash/kebabCase';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { cookiesSettingsAtom, userAtom } from 'recoil/atoms';
import { bannerSettingsSelector, userBidsSelector } from 'services/user/recoil';
import { filterZipcodeAtom } from 'services/zipcode/recoil';
import { hasPermission } from 'shared/utils/functions';
import { BookACall } from '../../components/atoms/BookACall';
import { ImportInventoryErrorsDialog } from '../../components/organisms/ImportInventoryErrorsDialog';
import { DonateBanner } from './DonateBanner';
import EmulateBar from './EmulateBar';
import { RedirectBanner } from './RedirectBanner';
import UserIcon from './UserIcon';

// GLOBAL IntersectionObserver fallback,
// added to prevent frontend crushes if IntersectionObserver is not supported by browser,
// and fallbackInView is not set
defaultFallbackInView(true);

function onLogoutClick(auth: Auth) {
  auth.logout();
  location.href = '/';
}

const Main = styledMui5('main')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;`;

const Content = styledMui5('section')`
  flex: 1;
  display: flex;
  flex-direction: column;`;

type Props = {
  hideFooter?: boolean;
};

type Context = {
  openRunnerUpDialog: () => void;
  isFooterInView: boolean;
};

export const LayoutContext = createContext({} as Context);

const Layout: FC<Props> = ({ children, hideFooter }) => {
  const router = useRouter();
  const runnerUpDialog = useRef<IRunnerUpDialog>(null);
  const [intersectionRef, isFooterInView] = useInView({
    threshold: 0.9,
    fallbackInView: true,
  });
  const bids = useRecoilValueLoadable(userBidsSelector).valueMaybe() || [];
  const [user] = useRecoilState(userAtom);
  const filterZipCode = useRecoilValue(filterZipcodeAtom);
  const isDealer = useRecoilValue(isDealerSelector);
  const isAdmin = useRecoilValue(isAdminSelector);
  const rebatesCustomZipcode = useRecoilValue(rebatesCustomZipcodeAtom);
  const bannerSettings =
    useRecoilValueLoadable(bannerSettingsSelector).valueMaybe() || undefined;

  const getBidForConfirmation = (bids: Bid[]) => {
    const bidForConfirmation = bids
      .filter((bid) => bid.auction.status === AuctionStatus.Completed)
      .find((bid) => bid.status === BidStatus.UserConfirmationNeeded);

    const timeNow = new Date().getTime();
    const deadlineTime = new Date(
      bidForConfirmation?.auction?.offer?.deadline,
    ).getTime();
    const isDialogExpired = timeNow > deadlineTime;

    if (isDialogExpired) {
      return;
    }
    return bidForConfirmation;
  };

  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();

  const isSmallScreenDevice = isExtraSmallScreen || isSmallScreen;

  const setCookiesSettings = useSetRecoilState(cookiesSettingsAtom);

  useEffect(() => {
    const handleAcceptCookie = () => {
      setCookiesSettings({ accepted: true });
    };

    window.addEventListener('CookiebotOnAccept', handleAcceptCookie, false);

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleAcceptCookie);
    };
  }, [setCookiesSettings]);

  const navLinks: Array<TopNavMenuItemProps & { isVisible?: boolean }> = [
    {
      name: 'Deals',
      href: filterZipCode ? `/deals?zipcode=${filterZipCode}` : '/deals',
      pathname: '/deals',
    },
    {
      name: 'About Us',
      href: '/about',
      isVisible: !hasPermission(user, Permission.admin),
    },
    {
      name: 'Users',
      href: '/admin/users',
      isVisible: hasPermission(user, Permission.admin),
    },
    {
      name: 'Dashboard',
      href: '/dashboard',
      isVisible: hasPermission(user, Permission.dealer),
    },
    {
      name: 'My Vehicles',
      href: '/vehicles',
      isVisible: hasPermission(user, Permission.user),
    },
    {
      name: 'Dashboard',
      href: '/admin',
      isVisible: hasPermission(user, Permission.admin),
    },
    {
      name: 'Data Dealerships',
      href: '/admin/datadealerships',
      isVisible: hasPermission(user, Permission.admin),
    },
    {
      name: 'Watchlist',
      href: '/watchlist',
      className: 'js-watchlist-button',
      isVisible:
        hasPermission(user, Permission.dealer) ||
        hasPermission(user, Permission.user),
    },
    {
      name: 'Recently Viewed',
      href: '/recently-viewed',
      isVisible: !hasPermission(user, Permission.admin),
    },
    {
      name: 'Blog',
      href: '/blog',
      isVisible: !hasPermission(user, Permission.admin),
    },
    {
      name: 'Autobandits',
      href: '/autobandits',
      isVisible: !hasPermission(user, Permission.admin),
    },
  ];

  const navActions: Array<TopNavMenuItemProps & { isVisible?: boolean }> = [
    {
      name: 'Log in',
      href: '/login',
      type: 'button',
      variant: 'outlined',
      className: 'js-login-button',
      isVisible: !user,
    },
    {
      name: 'Sign up',
      href: '/signup',
      type: 'button',
      variant: 'outlined',
      className: 'js-signup-button',
      isVisible: !user,
    },
    {
      name: 'Profile',
      className: 'js-user-menu',
      href: '/profile',
      isVisible:
        isSmallScreenDevice &&
        (hasPermission(user, Permission.dealer) ||
          hasPermission(user, Permission.user)),
      menuItems: [
        ...getTabs({ user }).map((tab) => ({
          name: tab,
          onClick: () => {
            router.push(`/profile/${kebabCase(tab)}`, undefined, {
              shallow: true,
            });
          },
        })),
      ],
    },
    {
      icon: () => <UserIcon img={user.img} />,
      name: user && (user.first_name || user.email),
      className: 'js-user-menu',
      isVisible: Boolean(user) && !isSmallScreenDevice,
      menuItems: [
        {
          name: 'Profile',
          href: '/profile',
          className: 'js-nav-link-profile',
        },
        {
          name: 'Logout',
          type: 'button',
          onClick: () => onLogoutClick(auth),
          className: 'js-nav-link-logout',
        },
      ],
    },
    {
      name: 'Logout',
      type: 'button',
      isVisible: Boolean(user) && isSmallScreenDevice,
      onClick: () => onLogoutClick(auth),
    },
  ];

  function getFooterBottomLinks(): FooterLink[] {
    return [
      {
        name: 'Privacy policy',
        href: '/privacy-policy',
      },
      {
        name: 'Terms & Conditions',
        href: hasPermission(user, Permission.dealer)
          ? '/dealer-terms'
          : '/terms',
      },
    ];
  }

  function getFooterLinks(): FooterLink[] {
    type LinkType = {
      name: string;
      href: string;
      onClick?: () => void;
    };
    const links: LinkType[] = [
      {
        name: 'Find a Car',
        href: filterZipCode ? `/deals?zipcode=${filterZipCode}` : '/deals',
      },
      {
        name: 'About us',
        href: '/about',
      },
      {
        name: 'Blog',
        href: `${process.env.PUBLIC_SITE_URL}/blog`,
      },
      {
        name: 'FAQ',
        href: '/faq-leasing',
      },
    ];
    if (!user) {
      links.push({
        name: 'User Log in',
        href: '/login',
      } as LinkType);
    }
    return links;
  }

  const context: Context = {
    openRunnerUpDialog: () => runnerUpDialog.current?.open?.(),
    isFooterInView,
  };

  const hideBookACall = isDealer || isAdmin;

  return (
    <Main>
      <ImportInventoryErrorsDialog />
      <PreventMultipleWinningBidDialog />
      <SelectAuctionRebatesDialog
        zipcode={isDealer ? rebatesCustomZipcode : null}
      />
      <ForwardToDialog />
      <RedirectBanner />
      {bannerSettings?.enabled && (
        <DonateBanner
          bgColor={bannerSettings.bg_color}
          textColor={bannerSettings.text_color}
          text={bannerSettings.text}
        />
      )}
      <NoSSR>
        <EmulateBar />
      </NoSSR>
      <TopNav
        links={navLinks.filter((link) => link.isVisible !== false)}
        actions={navActions.filter((link) => link.isVisible !== false)}
      />
      {getBidForConfirmation(bids) && (
        <RunnerUpDialog
          ref={runnerUpDialog}
          bid={getBidForConfirmation(bids)}
          user={user}
        />
      )}
      <Content>
        <LayoutContext.Provider value={context}>
          {children}
        </LayoutContext.Provider>
      </Content>
      {!hideBookACall && <BookACall />}
      {!hideFooter && (
        <div ref={intersectionRef}>
          <Footer
            links={getFooterLinks()}
            bottomLinks={getFooterBottomLinks()}
          />
        </div>
      )}
      <style jsx global>{`
        html,
        body {
          margin: 0;
        }
      `}</style>
    </Main>
  );
};

export default Layout;
