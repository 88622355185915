import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { clientStorage, cookiesSettingsAtom } from 'recoil/atoms';
import { v4 as uuid } from 'uuid';

function useInitUnathorisedUserId(): void {
  const cookiesSettings = useRecoilValue(cookiesSettingsAtom);
  const router = useRouter();

  useEffect(() => {
    const unathorisedUserId = clientStorage.get('unathorised_user_id');
    if (!unathorisedUserId) {
      clientStorage.set('unathorised_user_id', uuid());
    }
  }, [cookiesSettings, router.asPath]);
}

export const StrongPersistUnathorisedUserId: FC = () => {
  useInitUnathorisedUserId();

  return null;
};
