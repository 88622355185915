import { FC, useEffect, useState } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { styled, ThemeProvider } from '@mui/material/styles';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { userAtom } from 'recoil/atoms';
import ProgressButton from 'components/atoms/ProgressButton';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import Rest from 'services/rest';
import { User } from 'shared/types/models';
import { BRAND_NAME, getConsumerPaymentString } from 'shared/utils/constants';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { greyButtonLightTheme } from '../../../theme';
import { userServiceFeeSelector } from 'services/user/recoil';

const rest = new Rest();

type ButtonAlignment = 'left' | 'on-sides';

const PaymentFrom = styled('form')(({ theme }) => ({
  padding: theme.spacing(2, 0, 0),
}));

const ButtonHolder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignButtons',
})<{ alignButtons: ButtonAlignment }>(({ theme, alignButtons }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: alignButtons === 'left' ? 'flex-start' : 'space-between',
}));

const skipButtonStyles =
  (alignButtons: ButtonAlignment): SxProps<ThemeMui5> =>
  (theme) => ({
    '&.MuiButton-root': {
      marginRight: theme.spacing(alignButtons === 'left' ? 2 : 0),
    },
  });

type PaymentCardFormProps = {
  onSubmit: () => void | Promise<void>;
  user: User;
  alignButtons: ButtonAlignment;
  submitText?: string;
  skipText?: string;
  onSkip?: () => void;
};

const PaymentCardForm: FC<PaymentCardFormProps> = ({
  onSubmit,
  user,
  submitText = 'Add card',
  skipText = 'Skip this step',
  alignButtons = 'on-sides',
  onSkip,
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const setUser = useSetRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const userServiceFee = useRecoilValueLoadable(userServiceFeeSelector);

  useEffect(() => {
    if (stripe && elements) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: 'US',
                postal_code: user.zipcode,
              },
            },
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        const {
          setupIntent: { payment_method },
        } = result;

        await rest.setDefaultPaymentMethod(String(payment_method));
        setUser({ ...user, default_payment_method_id: String(payment_method) });
        await onSubmit();
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  if (!user) {
    return null;
  }

  return (
    <PaymentFrom onSubmit={handleSubmit} className={`js-add-payment-card-form`}>
      {error && (
        <Alert
          sx={{ marginBottom: (theme) => theme.spacing(2) }}
          severity='error'
          variant='standard'
        >
          {error}
        </Alert>
      )}
      <Typography
        variant='body2'
        gutterBottom
        color='textSecondary'
        sx={{ marginBottom: (theme) => theme.spacing(2) }}
      >
        You authorize {BRAND_NAME} to place a hold on your credit card in the
        amount of <b>{getConsumerPaymentString(userServiceFee.valueMaybe())}</b>{' '}
        and to immediately capture payment upon confirmation of the deal or
        release it from hold only if the deal is canceled by the dealer. Debit
        cards will be immediately captured as a payment upon adding a card and
        only be refunded if the deal is canceled by the dealer. You understand
        that your information will be saved to your profile for future deals.
      </Typography>

      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              address: {
                country: 'never',
                postalCode: 'never',
              },
            },
          },
        }}
        id='payment-element'
      />

      <ButtonHolder alignButtons={alignButtons}>
        {onSkip && (
          <ThemeProvider theme={greyButtonLightTheme}>
            <StyledButtonMui5
              color='grey'
              onClick={onSkip}
              sx={skipButtonStyles(alignButtons)}
            >
              {skipText}
            </StyledButtonMui5>
          </ThemeProvider>
        )}
        <ProgressButton
          color='primary'
          type='button'
          className='js-add-payment-card-submit'
          loading={isLoading}
          onClick={handleSubmit}
        >
          {submitText}
        </ProgressButton>
      </ButtonHolder>
    </PaymentFrom>
  );
};

export default PaymentCardForm;
