import { addDays, parseJSON } from 'date-fns';
import { CREDIT_TIERS } from '../config';
import { GetOrCreateVerificationSessionResponse as VerificationStatus } from '../types/api/getOrCreateVerificationSession';
import { UserTaskTypeEx } from '../types/misc';
import {
  Cosigner,
  CosignerRequestStatus,
  User,
  UserVehicle,
  UserVerificationStatus,
} from '../types/models';
import {
  ADD_A_COSIGNER_TASK,
  ADD_GUARANTEE_DEPOSIT_TASK,
  ADD_VEHICLES_TASK,
  CREDIT_CHECK_VALIDITY_IN_DAYS,
  CREDIT_SCORE_VALIDITY_TASK,
  EXPLORE_INCENTIVES_TASK,
  INCOME_CHECK_TASK,
  SOFT_CREDIT_CHECK_TASK,
  SOFT_CREDIT_CHECK_WIN_TASK,
  VERIFICATION_TASK,
} from './constants';
import { getCreditTierByScore } from './functions';

type GetUserTasksFunctionArguments = {
  user: User;
  cosigners: Cosigner[];
  vehicles: UserVehicle[];
  session: VerificationStatus;
  hasWonAuctions: boolean;
  cosignerNeeded: boolean;
  userPaymentCapturable: boolean;
};

const MAX_REQUIRED_CREDIT_SCORE_FOR_INCOME_CHECK = 650;
const MAX_REQUIRED_CREDIT_SCORE_FOR_COSIGNER_TASK = 550;

// User Tasks
const getSoftCreditCheckTaskEx = (user: User): UserTaskTypeEx => {
  const completed = Boolean(user.credit_score);
  if (completed) {
    const creditCheckValidityTask = getCreditCheckValidityTaskEx(user);

    if (!creditCheckValidityTask.completed) {
      return creditCheckValidityTask;
    }
  }

  return {
    ...SOFT_CREDIT_CHECK_TASK,
    required: true,
    completed: completed,
  };
};

const getSoftCreditCheckWinTaskEx = (user: User, required): UserTaskTypeEx => {
  const completed = Boolean(user.is_credit_tier_verified);
  if (completed) {
    const creditCheckValidityTask = getCreditCheckValidityTaskEx(user);

    if (!creditCheckValidityTask.completed) {
      return creditCheckValidityTask;
    }
  }

  return {
    ...SOFT_CREDIT_CHECK_WIN_TASK,
    required,
    completed: completed,
  };
};

const getIncomeCheckTaskEx = (
  user: User,
  hasWonAuctions = false,
): UserTaskTypeEx => {
  const completed = Boolean(user.income);
  const required =
    user.credit_score <= MAX_REQUIRED_CREDIT_SCORE_FOR_INCOME_CHECK &&
    hasWonAuctions;

  return {
    ...INCOME_CHECK_TASK,
    required,
    completed,
  };
};

const getAddGuaranteeDepositTaskEx = (
  userPaymentCapturable = false,
  required = true,
): UserTaskTypeEx => {
  return {
    ...ADD_GUARANTEE_DEPOSIT_TASK,
    required,
    completed: userPaymentCapturable,
  };
};

const getExploreIncentivesTaskEx = (user?: User): UserTaskTypeEx => {
  const required = false; // evaluate this value for later usage
  const completed = Boolean(user) && user.rebates_names?.length > 0;

  return {
    ...EXPLORE_INCENTIVES_TASK,
    required,
    completed,
  };
};

const getAddVehiclesTaskEx = (vehicles?: UserVehicle[]): UserTaskTypeEx => {
  const required = false; // evaluate this value for later usage
  const completed = Array.isArray(vehicles) && vehicles.length > 0;

  return {
    ...ADD_VEHICLES_TASK,
    required,
    completed,
  };
};

const getVerificationTaskEx = (
  session?: VerificationStatus,
  required = true,
): UserTaskTypeEx => {
  const completed =
    Boolean(session) && session.status === UserVerificationStatus.Approved;

  return {
    ...VERIFICATION_TASK,
    required,
    completed,
  };
};

export const getCossignersAcceptedOffer = (cosigners?: Cosigner[]) => {
  return (cosigners || []).filter(
    (cosigner) =>
      cosigner.request_status === CosignerRequestStatus.CosignerAccepted,
  );
};

export const getQualifiedCosigners = (cosigners?: Cosigner[]) => {
  return (cosigners || []).filter((cosigner) => {
    const tier = CREDIT_TIERS.find((tier) => tier.name === cosigner.tier);

    return tier && !tier?.isCosignerRequired;
  });
};

export const getQualifiedCosignersAcceptedOffer = (cosigners?: Cosigner[]) => {
  const cosignersAcceptedOffer = getCossignersAcceptedOffer(cosigners);

  return cosignersAcceptedOffer.filter((cosigner) => {
    const tier = CREDIT_TIERS.find((tier) => tier.name === cosigner.tier);

    return tier && !tier?.isCosignerRequired;
  });
};

const getAddACosignerTaskEx = (
  cosigners?: Cosigner[],
  cosignerNeeded?: boolean,
): UserTaskTypeEx => {
  const required = cosignerNeeded;

  const hasCosigner = Array.isArray(cosigners) && cosigners.length > 0;

  const hasQualifiedCosignerAcceptedOffer =
    getQualifiedCosignersAcceptedOffer(cosigners).length > 0;

  const hasQualifiedCosigner = getQualifiedCosigners(cosigners).length > 0;

  const completed = hasCosigner && hasQualifiedCosignerAcceptedOffer;

  const getTitle = () => {
    if (hasCosigner) {
      if (!hasQualifiedCosigner) {
        return 'Co-signer has been deemed ineligible due to their low credit score. Please add another one.';
      } else {
        if (!hasQualifiedCosignerAcceptedOffer) {
          return 'Wait until cosigner accepted request';
        }
      }
    } else {
      return ADD_A_COSIGNER_TASK.title;
    }
  };

  return {
    ...ADD_A_COSIGNER_TASK,
    title: getTitle(),
    required,
    completed,
  };
};

const getCreditCheckValidityTaskEx = (user: User): UserTaskTypeEx => {
  return {
    ...CREDIT_SCORE_VALIDITY_TASK,
    required: true,
    completed:
      addDays(
        // parseJSON is the right way to parse timestampz
        parseJSON(user.soft_credit_check_passed_at),
        CREDIT_CHECK_VALIDITY_IN_DAYS,
      ).getTime() > Date.now(),
  };
};

export const getUserTasksEx = ({
  user,
  cosigners,
  vehicles,
  session,
  hasWonAuctions,
  cosignerNeeded,
  userPaymentCapturable,
}: GetUserTasksFunctionArguments): Array<UserTaskTypeEx> => {
  const tier = getCreditTierByScore(user.credit_score);

  cosignerNeeded =
    tier?.isCosignerRequired ||
    (user.is_credit_tier_verified &&
      user.credit_score <= MAX_REQUIRED_CREDIT_SCORE_FOR_COSIGNER_TASK) ||
    cosignerNeeded;

  return [
    getSoftCreditCheckTaskEx(user),
    getSoftCreditCheckWinTaskEx(user, hasWonAuctions),
    getExploreIncentivesTaskEx(user),
    getAddVehiclesTaskEx(vehicles),
    getVerificationTaskEx(session, hasWonAuctions),
    getAddACosignerTaskEx(cosigners, cosignerNeeded),
    getIncomeCheckTaskEx(user, hasWonAuctions),
    getAddGuaranteeDepositTaskEx(userPaymentCapturable, hasWonAuctions),
  ];
};

const requiredProfileFields: (keyof User)[] = [
  'first_name',
  'last_name',
  'birth_date',
  'mobile_phone',
  'address',
  'zipcode',
];

export const isProfileCompleted = (user: User): boolean => {
  return requiredProfileFields.every((field) => Boolean(user[field]));
};
