import { DEFAULT_DOWN_PAYMENT } from 'data/constants';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { MSAPIScanMode } from 'shared/types/marketScan';
import {
  Auction,
  AuctionBid,
  Quote,
  QuoteRequest,
  TierScore,
  TierScoreValueMap,
  UserBid,
  WinningBid,
} from 'shared/types/models';
import {
  DEFAULT_MILES_PER_YEAR,
  DEFAULT_TERM_LENGTH,
  SearchTerm,
} from 'shared/utils/constants';
import { DueAtSigningType } from '../../../shared/types/api/getProgram';
import { SAFE_RECOIL_CACHE } from 'recoil/safeRecoilCache';

// Core data atoms from props
export const auctionAtom = atom<Auction>({
  key: 'AUCTION_ATOM',
  default: null,
});

export const winningBidAtom = atom<WinningBid>({
  key: 'WINNING_BID_ATOM',
  default: null,
});

export const userBidAtom = atom<UserBid>({
  key: 'USER_BID_ATOM',
  default: null,
});

export const auctionBidsAtom = atom<AuctionBid[]>({
  key: 'AUCTION_BIDS_ATOM',
  default: [],
});

// Atoms to support UI
export const monthlyPaymentInputAtom = atom<number>({
  key: 'monthlyPaymentInputAtom',
  default: null,
});

export const rebatesCustomZipcodeAtom = atom<string>({
  key: 'rebatesCustomZipcodeAtom',
  default: '',
});

const moInputAtomLease = atom<SearchTerm>({
  key: 'moInputAtomLease',
  default: DEFAULT_TERM_LENGTH,
});

const moInputAtomFinance = atom<SearchTerm>({
  key: 'moInputAtomFinance',
  default: DEFAULT_TERM_LENGTH,
});

export const moInputSelector = selector<SearchTerm>({
  key: 'moInputSelector',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: ({ get }) => {
    const scanMode = get(scanModeInputAtom);

    return scanMode === MSAPIScanMode.Lease
      ? get(moInputAtomLease)
      : get(moInputAtomFinance);
  },
  set: ({ get, set }, newValue) => {
    const scanMode = get(scanModeInputAtom);

    scanMode === MSAPIScanMode.Lease
      ? set(moInputAtomLease, newValue)
      : set(moInputAtomFinance, newValue);
  },
});

export const quoteAtom = atom<Quote>({
  key: 'quoteAtom',
  default: null,
});

export const quoteRequestAtom = atom<QuoteRequest>({
  key: 'quoteRequestAtom',
  default: null,
});

const downPaymentInputAtomFamilyLease = atomFamily<number, number>({
  key: 'downPaymentInputAtomLease',
  default: undefined,
});

const downPaymentInputAtomFamilyFinance = atomFamily<number, number>({
  key: 'downPaymentInputAtomFinance',
  default: DEFAULT_DOWN_PAYMENT,
});

export const downPaymentInputSelectorFamily = selectorFamily<number, number>({
  key: 'downPaymentInputSelector',
  get:
    (auctionId) =>
    ({ get }) => {
      const scanMode = get(scanModeInputAtom);
      const searchDownPayment = get(searchDownPaymentInputAtom);

      if (scanMode === MSAPIScanMode.Lease) {
        return get(downPaymentInputAtomFamilyLease(auctionId));
      } else {
        const auctionDownPayment = get(
          downPaymentInputAtomFamilyFinance(auctionId),
        );
        if (
          searchDownPayment !== DEFAULT_DOWN_PAYMENT &&
          auctionDownPayment === DEFAULT_DOWN_PAYMENT
        ) {
          return searchDownPayment;
        }

        return auctionDownPayment;
      }
    },
  set:
    (auctionId) =>
    ({ get, set }, newValue) => {
      const scanMode = get(scanModeInputAtom);

      scanMode === MSAPIScanMode.Lease
        ? set(downPaymentInputAtomFamilyLease(auctionId), newValue)
        : set(downPaymentInputAtomFamilyFinance(auctionId), newValue);
    },
});

export const searchDownPaymentInputAtom = atom<number>({
  key: 'searchDownPaymentInputAtom',
  default: DEFAULT_DOWN_PAYMENT,
});

export const upfrontAcquisitionFeeInputAtomFamily = atomFamily<boolean, number>(
  {
    key: 'upfrontAcquisitionFeeInputAtomFamily',
    default: true,
  },
);

const dueAtSigningTypeInputAtomFamilyLease = atomFamily<
  DueAtSigningType,
  number
>({
  key: 'dueAtSigningInputAtomLease',
  default: DueAtSigningType.FIRST_MONTHLY_PLUS_ALL_FEES,
});

const dueAtSigningTypeInputAtomFamilyFinance = atomFamily<
  DueAtSigningType,
  number
>({
  key: 'dueAtSigningInputAtomFinance',
  default: DueAtSigningType.ZERO_SIGNING_DOWNPAYMENT_ONLY,
});

export const dueAtSigningTypeInputSelectorFamily = selectorFamily<
  DueAtSigningType,
  number
>({
  key: 'dueAtSigningInputSelector',
  get:
    (auctionId) =>
    ({ get }) => {
      const scanMode = get(scanModeInputAtom);

      return scanMode === MSAPIScanMode.Lease
        ? get(dueAtSigningTypeInputAtomFamilyLease(auctionId))
        : get(dueAtSigningTypeInputAtomFamilyFinance(auctionId));
    },
  set:
    (auctionId) =>
    ({ get, set }, newValue) => {
      const scanMode = get(scanModeInputAtom);

      scanMode === MSAPIScanMode.Lease
        ? set(dueAtSigningTypeInputAtomFamilyLease(auctionId), newValue)
        : set(dueAtSigningTypeInputAtomFamilyFinance(auctionId), newValue);
    },
});

export const msdInputAtom = atom<number>({ key: 'msdInputAtom', default: 0 });

export const zeroDriveOffInputAtom = atom<boolean>({
  key: 'zeroDriveOffInputAtom',
  default: false,
});

export const onePayLeaseInputAtom = atom<boolean>({
  key: 'onePayLeaseInputAtom',
  default: false,
});

export const waiveTheAcquisitionFeeInputAtom = atom<boolean>({
  key: 'waiveTheAcquisitionFeeInputAtom',
  default: false,
});

export const milesInputAtom = atom<number>({
  key: 'milesInputAtom',
  default: DEFAULT_MILES_PER_YEAR,
});

export const isOwnersChoiceInputAtom = atom<boolean>({
  key: 'ownersChoiceInputAtom',
  default: false,
});

export const withIncentivesAtom = atom<boolean>({
  key: 'areIncentivesAvailableAtom',
  default: true,
});

export const creditScoreInputAtom = atom<number>({
  key: 'creditScoreInputAtom',
  default: TierScoreValueMap[TierScore.SuperElite],
});

export const scanModeInputAtom = atom<
  MSAPIScanMode.Lease | MSAPIScanMode.Retail
>({
  key: 'scanModeInputAtom',
  default: MSAPIScanMode.Lease,
});
