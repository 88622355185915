import omit from 'lodash/omit';
import { SearchTerm } from '../../utils/constants';
import {
  MSAPIAppliedRebate,
  MSAPIProgramReference,
  MSAPIRequestRebateCategory,
  MSAPIScanMode,
} from '../marketScan';

export type Program = {
  MaxCreditScore: MSAPIProgramReference['MaxCreditScore'];
  MinCreditScore: MSAPIProgramReference['MinCreditScore'];
  AppliedRebate?: MSAPIAppliedRebate[];
  AcquisitionFee: MSAPIProgramReference['AcquisitionFee'];
  ID: MSAPIProgramReference['ID'];
  Payment: MSAPIProgramReference['Payment'];
  Price: MSAPIProgramReference['Price'];
  SecurityDeposit: MSAPIProgramReference['SecurityDeposit'];
  ResidualValue: MSAPIProgramReference['ResidualValue'];
  AmountFinanced: MSAPIProgramReference['AmountFinanced'];
  FullDetails: {
    APRLMF: MSAPIProgramReference['FullDetails']['APRLMF'];
    APRLMFType: MSAPIProgramReference['FullDetails']['APRLMFType'];
    DaysToFirstPayment: MSAPIProgramReference['FullDetails']['DaysToFirstPayment'];
    UI: MSAPIProgramReference['FullDetails']['UI'];
    MSRP: MSAPIProgramReference['FullDetails']['MSRP'];
    TransactionType: MSAPIProgramReference['FullDetails']['TransactionType'];
    CurrentMileage: MSAPIProgramReference['FullDetails']['CurrentMileage'];
    Description: MSAPIProgramReference['FullDetails']['Description'];
    Downpayment: MSAPIProgramReference['FullDetails']['Downpayment'];
    DownpaymentFromCash: MSAPIProgramReference['FullDetails']['DownpaymentFromCash'];
    DownpaymentFromRebate: MSAPIProgramReference['FullDetails']['DownpaymentFromRebate'];
    ExpirationDate: MSAPIProgramReference['FullDetails']['ExpirationDate'];
    RegistrationFee: MSAPIProgramReference['FullDetails']['RegistrationFee'];
    Term: MSAPIProgramReference['FullDetails']['Term'];
    VehicleID: MSAPIProgramReference['FullDetails']['VehicleID'];
    AdjustedSellingPrice: MSAPIProgramReference['FullDetails']['AdjustedSellingPrice'];
    PartRecap: MSAPIProgramReference['FullDetails']['PartRecap'];
    Lease: {
      AcqusitionFeeInCap: MSAPIProgramReference['FullDetails']['Lease']['AcqusitionFeeInCap'];
      AnnualMileage: MSAPIProgramReference['FullDetails']['Lease']['AnnualMileage'];
      IsOnePay: MSAPIProgramReference['FullDetails']['Lease']['IsOnePay'];
      TotalDueAtDelivery: MSAPIProgramReference['FullDetails']['Lease']['TotalDueAtDelivery'];
      TotalTaxInInception: MSAPIProgramReference['FullDetails']['Lease']['TotalTaxInInception'];
      SecurityDeposit: MSAPIProgramReference['FullDetails']['Lease']['SecurityDeposit'];
    };
    Retail: MSAPIProgramReference['FullDetails']['Retail'];
    LenderName: MSAPIProgramReference['FullDetails']['LenderName'];
    Lender: MSAPIProgramReference['FullDetails']['Lender'];
  };
};

export type ProgramExtraFields = {
  ZIP: string;
  DealerZIP: string;
  ZeroDriveOff: boolean;
  DueAtSigningType: DueAtSigningType;
  BestTerm: number;
  AvailableTerms: number[];
  isFromCache: boolean;
  storageId: string;
  InvoicePrice: number;
  CreditScore: number;
  RequestTerm: SearchTerm;
  AcquisitionFeeUpfront: boolean;
};

export type ProgramVehicleRequest = {
  salesPrice?: number;
  monthlyPayment?: number;
  vehicleId: number;
  requestId: number;
  msrp: number;
  invoicePrice: number;
  demoMileage: number;
};

export type GetProgramsRequest = {
  programId?: number;
  vehicles: Array<ProgramVehicleRequest>;
  creditScore: number;
  zipcode: string;
  dealerZipcode: string;
  miles: number;
  mo?: SearchTerm;
  msd?: number;
  downPayment: number;
  upfrontAcquisitionFee?: boolean;
  waiveTheAcquisitionFee?: boolean;
  onePayLease?: boolean;
  zeroDriveOff: boolean;
  isOwnersChoice?: boolean;
  useOnlyLenderCode?: string[];
  rebates: MSAPIAppliedRebate[];
  rebatesCategories?: MSAPIRequestRebateCategory[];
  disableAutoRebates?: boolean;
  dueAtSigningType?: DueAtSigningType;
  scanMode: MSAPIScanMode.Lease | MSAPIScanMode.Retail;
};

export enum DueAtSigningType {
  ZERO_SIGNING = 'zero_signing',
  ONLY_FIRST_MONTHLY_PAYMENT = 'only_fist_monthly_payment',
  FIRST_MONTHLY_PLUS_ALL_FEES = 'first_monthly+all_fees',
  FIRST_MONTHLY_ALL_FEES_SALES_TAX = 'first_monthly+all_fees+sales_tax',
  CUSTOM_INPUT = 'custom_input',
  ZERO_SIGNING_DOWNPAYMENT_ONLY = 'zero_signing_downpayment_only',
}

export enum DueAtSigningTypeIndex {
  ZERO_SIGNING_INDEX = 0,
  FIRST_MONTHLY_INDEX = 1,
  FIRST_MONTHLY_FEES_INDEX = 2,
  FIRST_MONTHLY_FEES_SALES_TAX_INDEX = 3,
  CUSTOM_SIGNING_INDEX = 4,
  ZERO_SIGNING_DOWNPAYMENT_ONLY_INDEX = 5,
}

const DueAtSigningTypeIsCachable: {
  [key in DueAtSigningType]: boolean;
} = {
  [DueAtSigningType.ZERO_SIGNING]: true,
  [DueAtSigningType.ONLY_FIRST_MONTHLY_PAYMENT]: true,
  [DueAtSigningType.FIRST_MONTHLY_PLUS_ALL_FEES]: true,
  [DueAtSigningType.FIRST_MONTHLY_ALL_FEES_SALES_TAX]: true,
  [DueAtSigningType.CUSTOM_INPUT]: false,
  [DueAtSigningType.ZERO_SIGNING_DOWNPAYMENT_ONLY]: false,
};

const NOT_CACHEABLE_DUE_AT_SIGNING_TYPE_KEYS = Object.keys(
  DueAtSigningType,
).filter((key) => !DueAtSigningTypeIsCachable[DueAtSigningType[key]]);

export const CACHEABLE_DUE_AT_SIGNING_TYPE = Object.values(
  omit(DueAtSigningType, NOT_CACHEABLE_DUE_AT_SIGNING_TYPE_KEYS),
);

export type GetProgramRequest = {
  programId?: number;
  auctionId: number;
  salesPrice?: number;
  monthlyPayment?: number;
  creditScore: number;
  zipcode: string;
  dealerZipcode?: string;
  vehicleId: number;
  msrp: number;
  invoicePrice: number;
  miles: number;
  mo?: SearchTerm;
  msd?: number;
  downPayment: number;
  upfrontAcquisitionFee?: boolean;
  waiveTheAcquisitionFee?: boolean;
  demoMileage: number;
  onePayLease?: boolean;
  zeroDriveOff: boolean;
  isOwnersChoice?: boolean;
  rebates: MSAPIAppliedRebate[];
  rebatesCategories?: MSAPIRequestRebateCategory[];
  disableAutoRebates?: boolean;
  useOnlyLenderCode?: string[];
  dueAtSigningType?: DueAtSigningType;
  programsCount?: number;
  scanMode: MSAPIScanMode.Lease | MSAPIScanMode.Retail;
};

// GetProgramResponse is a stupid name, TODO: rename it
export type GetProgramResponse = Program & ProgramExtraFields;

export type GetMultiVehiclesProgramsResponse = Array<{
  requestId: number;
  programs: GetProgramResponse[];
}>;
