import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import CloseIcon from '@mui/icons-material/Close';
import { SwipeableDrawer, Zoom } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StandardLonghandProperties } from 'csstype';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useGTagTrackBookACallPopup } from '../../hooks/useGTagTrackBookACallPopup';
import { useIsTouchDevice } from '../../hooks/useIsTouchDevice';
import { useScreenSize } from '../../hooks/useScreenSize';
import {
  bookACallDrawerOpen,
  bookACallDrawerOpenedThisSession,
} from '../../recoil/atoms';
import { ENVIRONMENT } from '../../shared/config';
import { CALENDLY_WIDGET_DATA } from '../../shared/utils/constants';
import { grey } from '../../theme/colors';
import { StyledFontAwesomeIconMui5 } from './FontAwesomeIcon';
import { NoSSR } from './NoSSR';
import { StyledButtonMui5 } from './StyledButton';
import gasPistol from '/public/img/gas_pistol.png';

const EXCLUDED_ROUTES = [
  '/deals/[slug]/dealer-receipt',
  '/deals/[slug]/receipt',
  '/quotes/quote-request/[id]',
];

const CalendlyWidget: FC = () => {
  function initCalendlyInlineWidget() {
    // @ts-expect-error we do not have types for Calendly
    if (typeof Calendly?.initInlineWidget !== 'function') return;
    // @ts-expect-error we do not have types for Calendly
    Calendly.initInlineWidget({
      ...CALENDLY_WIDGET_DATA,
      parentElement: document.querySelector('.calendly-inline-widget'),
      resize: true,
    });
  }

  useEffect(() => {
    initCalendlyInlineWidget();
  }, []);

  return (
    <div
      className='calendly-inline-widget'
      data-url={CALENDLY_WIDGET_DATA.url}
      style={{ minWidth: '320px' }}
    />
  );
};

const Content: FC = () => {
  const [hideMessage, setHideMessage] = useState(false);

  if (hideMessage) {
    return <CalendlyWidget />;
  }

  return (
    <>
      <Typography
        variant='body2'
        color='textSecondary'
        textAlign='center'
        textTransform='uppercase'
        sx={{ pt: 1.4 }}
      >
        Still searching for that perfect deal?
      </Typography>
      <Typography
        variant='h5'
        component='p'
        textAlign='center'
        textTransform='uppercase'
        gutterBottom
      >
        <b>Let us do the heavy lifting!</b>
      </Typography>
      <Typography
        variant='body2'
        color='textSecondary'
        textAlign='center'
        gutterBottom
      >
        Book a quick call with one of our Deal Scouts and we&apos;ll make you
        the best offer on the market.
      </Typography>
      <Box
        mt={2}
        mb={1}
        px={2.4}
        sx={(t) => ({
          height: '52px',
          backgroundColor: '#EDEDED',
          borderRadius: '5px',
          gap: t.spacing(2.4),
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: '8px',
            width: '80px',
            height: '80px',
            background: `url(${gasPistol.src}) no-repeat center center`,
          },
        })}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          variant='h6'
          color='primary'
          component='div'
          display='block'
          textTransform='uppercase'
        >
          <b>bonus</b>
        </Typography>
        <Typography
          variant='body2'
          color='primary'
          component='div'
          display='block'
          textTransform='uppercase'
          sx={{ fontSize: '0.8em', lineHeight: '1em', pr: '50px' }}
        >
          Get a <b>$50 gas card</b> just for hopping on the call!
        </Typography>
      </Box>
      <StyledButtonMui5
        fullWidth
        color='secondary'
        size='small'
        onClick={() => {
          setHideMessage(true);
        }}
        sx={{ '&.MuiButtonBase-root': { borderRadius: '5px', mb: 1 } }}
      >
        <Typography variant='h6' component='span' color='inherit'>
          <b>Book a Call Now</b> <StyledFontAwesomeIconMui5 icon={faPhone} />
        </Typography>
      </StyledButtonMui5>
      <Typography
        variant='body2'
        color='textSecondary'
        textAlign='center'
        sx={{ fontSize: '0.5em' }}
      >
        *To qualify for the gas gift card, you must have a credit score of 600
        or higher.
      </Typography>
    </>
  );
};

const StyledSwipeableDrawer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'isCompact' &&
    prop !== 'drawerWidth' &&
    prop !== 'maxDrawerHeight' &&
    prop !== 'drawerBleeding',
})<{
  isCompact?: boolean;
  drawerWidth: StandardLonghandProperties['width'];
  maxDrawerHeight: StandardLonghandProperties['height'];
  drawerBleeding: StandardLonghandProperties['height'];
}>(({ isCompact, drawerWidth, maxDrawerHeight, drawerBleeding }) => ({
  zIndex: 1000,
  width: isCompact ? '100%' : drawerWidth,
  left: `calc(100% - ${isCompact ? '100%' : `${drawerWidth}`})`,
  '& .MuiDrawer-paper': {
    width: isCompact ? '100%' : `${drawerWidth}`,
    left: `calc(100% - ${isCompact ? '100%' : `${drawerWidth}`})`,
    maxHeight: `calc(${maxDrawerHeight} - ${drawerBleeding})`,
    overflow: 'visible',
  },
}));

const Puller = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'open',
})<{
  color: StandardLonghandProperties['color'];
  open?: boolean;
}>(({ theme, color }) => ({
  width: 70,
  height: 4,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: `calc(50% - 35px)`,
  backgroundColor: color,
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
    delay: open ? theme.transitions.duration.enteringScreen : 0,
  }),
}));

const DrawerBleedBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'drawerBleeding' && prop !== 'backgroundColor' && prop !== 'color',
})<{
  drawerBleeding: StandardLonghandProperties['height'];
  backgroundColor: StandardLonghandProperties['backgroundColor'];
  color: StandardLonghandProperties['color'];
  open?: boolean;
}>(({ theme, drawerBleeding, backgroundColor, color, open }) => ({
  position: 'absolute',
  top: `calc(0px - ${drawerBleeding})`,
  height: drawerBleeding,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  visibility: 'visible',
  right: 0,
  left: 0,
  backgroundColor: backgroundColor,
  color: color,
  transition: theme.transitions.create(['background-color', 'color'], {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
    delay: open ? theme.transitions.duration.enteringScreen : 0,
  }),
  ...(!open && { cursor: 'pointer' }),
}));

const CloseFab = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(1.5),
  width: '1.8em',
  height: '1.8em',
  fontSize: '1.2em',
  minHeight: 'unset',
  boxShadow: 'none !important',
  background: 'transparent',
}));

const DrawerContentBox = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 0,
    height: '100%',
    overflow: 'auto',
  },
}));

const CustomSwipeableDrawer: FC<{
  title: string;
  swipeAreaWidth: number;
  drawerWidth: StandardLonghandProperties['width'];
  maxDrawerHeight: StandardLonghandProperties['height'];
}> = ({ children, title, swipeAreaWidth, drawerWidth, maxDrawerHeight }) => {
  const { isExtraSmallScreen: isCompact } = useScreenSize();

  const drawerBleeding = `${swipeAreaWidth}px`;

  const [drawerOpen, setDrawerOpen] = useRecoilState(bookACallDrawerOpen);

  const drawerContainer =
    typeof document !== 'undefined' ? () => document.body : undefined;

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  // weirdly, works only on remount, in real case scenario this shouldn't be an issue
  const isTouchScreen = useIsTouchDevice();

  const theme = useTheme();
  const closeButtonZoomTransition = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { gtagTrackBookACallOpen } = useGTagTrackBookACallPopup();

  return (
    <NoSSR>
      <StyledSwipeableDrawer
        isCompact={isCompact}
        drawerWidth={drawerWidth}
        maxDrawerHeight={maxDrawerHeight}
        drawerBleeding={drawerBleeding}
        {...(!isTouchScreen && { variant: 'persistent' })}
        container={drawerContainer}
        anchor='bottom'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => {
          gtagTrackBookACallOpen();
          setDrawerOpen(true);
        }}
        swipeAreaWidth={swipeAreaWidth}
        disableSwipeToOpen={!iOS}
        disableDiscovery={iOS}
        ModalProps={{ keepMounted: true }}
      >
        <DrawerBleedBox
          className='js-book-a-call'
          drawerBleeding={drawerBleeding}
          {...(!drawerOpen &&
            !isTouchScreen && {
              onClick: () => {
                gtagTrackBookACallOpen();
                setDrawerOpen((v) => !v);
              },
            })}
          open={drawerOpen}
          backgroundColor={
            drawerOpen
              ? theme.palette.primary.main
              : theme.palette.secondary.main
          }
          color={
            drawerOpen
              ? theme.palette.primary.contrastText
              : theme.palette.secondary.contrastText
          }
        >
          <Puller
            open={drawerOpen}
            color={drawerOpen ? grey[300] : theme.palette.secondary.light}
          />
          <Typography
            variant='h6'
            component='p'
            color='inherit'
            textAlign='center'
            textTransform='uppercase'
            sx={{ p: 1.3 }}
          >
            <b>{title}</b>
            <Zoom
              in={drawerOpen && !isTouchScreen}
              timeout={closeButtonZoomTransition}
              style={{
                transitionDelay: `${
                  drawerOpen ? closeButtonZoomTransition.enter : 0
                }ms`,
              }}
              unmountOnExit
            >
              <CloseFab
                color={'primary'}
                size={'small'}
                sx={(t) => ({ fontSize: t.typography.body1.fontSize })}
                onClick={() => {
                  setDrawerOpen(false);
                }}
              >
                <CloseIcon />
              </CloseFab>
            </Zoom>
          </Typography>
        </DrawerBleedBox>
        <DrawerContentBox>{children}</DrawerContentBox>
      </StyledSwipeableDrawer>
    </NoSSR>
  );
};

export const BookACall: FC = () => {
  const router = useRouter();

  const title = 'Get $50 Gas Card';
  const swipeAreaWidth = 50;
  const drawerWidth = '400px';
  const maxDrawerHeight: StandardLonghandProperties['height'] =
    'calc(100% - 100px)';

  const isE2E = process.env.ENVIRONMENT === ENVIRONMENT.E2E;
  const { isExtraSmallScreen: isCompact } = useScreenSize();
  const setDrawerOpen = useSetRecoilState(bookACallDrawerOpen);
  const [wasOpenedThisSession, setWasOpenedThisSession] = useRecoilState(
    bookACallDrawerOpenedThisSession,
  );

  const expansionTimeoutMs = 20000;
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (!wasOpenedThisSession && !isCompact && !isE2E) {
        setDrawerOpen(true);
        setWasOpenedThisSession(true);
      }
    }, expansionTimeoutMs);
    return () => clearTimeout(timeoutHandler);
  }, [
    isCompact,
    isE2E,
    setDrawerOpen,
    setWasOpenedThisSession,
    wasOpenedThisSession,
  ]);

  if (EXCLUDED_ROUTES.includes(router.pathname)) return null;

  return (
    <CustomSwipeableDrawer
      title={title}
      swipeAreaWidth={swipeAreaWidth}
      drawerWidth={drawerWidth}
      maxDrawerHeight={maxDrawerHeight}
    >
      <Content />
    </CustomSwipeableDrawer>
  );
};
