import Alert from '@mui/material/Alert';
import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  donateBannerOpenStateAtom,
  redirectBannerOpenStateAtom,
} from 'recoil/atoms';
import Link from '@mui/material/Link';
import NextLinkMui5 from '../../components/atoms/NextLink';
import { green } from 'theme/colors';
import Typography from '@mui/material/Typography';

const BannerAlert = styled(Alert)(
  ({ theme }) => `
  &.MuiAlert-root {
    padding: ${theme.spacing(0, 2)};
    border-radius: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    & .MuiAlert-icon {
      display: none;
    }
    & .MuiAlert-message {
      font-weight: bold;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }`,
);

type BannerProps = {
  text?: string;
  bgColor?: string;
  textColor?: string;
  hidden?: boolean;
};

interface ConvertMdLinksProps {
  text: string;
  textColor?: string;
}

export function convertMdLinksInText({
  text,
  textColor = '#ffffff',
}: ConvertMdLinksProps): ReactNode {
  // Regex to match Markdown links: [linkText](URL)
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const parts: ReactNode[] = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    // Push plain text before the Markdown link
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    const linkText = match[1];
    const url = match[2];

    parts.push(
      <Link
        key={match.index}
        component={NextLinkMui5}
        sx={{ color: textColor, textDecorationColor: textColor }}
        href={url}
      >
        {linkText}
      </Link>,
    );

    lastIndex = regex.lastIndex;
  }

  // Add any remaining plain text after the last Markdown link
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return <>{parts}</>;
}

export const DonateBanner: FC<BannerProps> = ({
  text,
  bgColor = green[900],
  textColor = 'white',
  hidden = false,
}) => {
  const [isOpened, setIsOpened] = useRecoilState(donateBannerOpenStateAtom);
  const isRedirectBannerOpened = useRecoilValue(redirectBannerOpenStateAtom);
  const parsedText = text ? convertMdLinksInText({ text, textColor }) : '';

  return (
    <>
      {!hidden && !isRedirectBannerOpened && isOpened && (
        <BannerAlert
          variant='filled'
          style={{ background: bgColor }}
          onClose={() => {
            setIsOpened(false);
          }}
        >
          <Typography color={textColor} fontWeight={'bold'} variant={'body2'}>
            {parsedText}
          </Typography>
        </BannerAlert>
      )}
    </>
  );
};
