import { RebatesCategoriesDialogState } from 'components/RebatesCategoriesDialog';
import {
  defaultRebatesCategoriesAnswers,
  PageTransitionEnum,
  rebatesCategoriesQuestions,
} from 'data/constants';
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
} from 'recoil';
import Rest from 'services/rest';
import { GetGoogleReviewsResponse } from 'shared/types/api/getGoogleReviews';
import { GetMarketScanModelYearsRequest } from 'shared/types/api/getMarketScanModelYears';
import { Identity } from 'shared/types/common';
import { Question, Rebate, RebateQuestion, User } from 'shared/types/models';
import {
  CLIENT_STORAGE_TYPE,
  getClientStorage,
  LOCAL_STORAGE_TYPE,
} from 'utils/ClientStorage';
import { ForwardDialogArgs } from '../hooks/useForwardToDialog';
import { getRebatesCategoriesFromAnswersUsingQuestions } from '../services/rebates/functions';
import { GetFutureByListedAuctionsResponse } from '../shared/types/api/getFutureByListedAuctions';
import {
  MSAPIMake,
  MSAPIModel,
  MSAPIRequestRebateCategory,
  MSAPIYear,
  MSISRebateCategory,
} from '../shared/types/marketScan';
import { clientStorageEffect } from './effects';
import { SAFE_RECOIL_CACHE } from './safeRecoilCache';
import { userRebatesCategoriesAnswersSelector } from './selectors';
import { recoilPersist } from './utils/recoilPersist';

export const USER_REBATES_STORAGE_KEY = 'questions';
export const COOKIES_SETTINGS_STORAGE_KEY = 'cookies-settings';

export const DEFAULT_APPLIED_REBATES = [];

const rest = new Rest();

export const clientStorage = getClientStorage(CLIENT_STORAGE_TYPE);
export const localStorage = getClientStorage(LOCAL_STORAGE_TYPE);

/**
 * This is the time atom, it is updating every second.
 * Subscribe to it in case you need to periodically render any data.
 * Good example of usage is clocks, times, or any case when you have to
 * fetch some data from server every X seconds
 */
export const timeAtom = atom<number>({
  key: 'TIME_ATOM',
  default: new Date().getTime(),
});

/**
 * This is the user atom. The only source of truth you have to use
 * to retrieve any user data. You have to update this atom every time
 * you are changing user data. (profile, questions, etc)
 */
export const userAtom = atom<User>({ key: 'USER_ATOM', default: null });

/**
 * This atom is updaing on every page transition.
 * Subscribe to it in case you have to visualize transitions
 * between pages or show some spinners while getServerSideProps
 * function for the next page is fetching the data
 */
export const pageTransitionAtom = atom<PageTransitionEnum>({
  key: 'PAGE_TRANSITION_ATOM',
  default: null,
});

/**
 * This atom is a storage for unauthorized users rebates names.
 * Clien storage is based on limited cookie storage.
 * Every browser apply its ownlimitations.
 * It is updating automatically every time when user select / deselect rebate.
 * I would not recommend to use it directly, use "userRebatesSelector" insted,
 * it has full rebates data for authorized and non authorized users.
 */
export const userClientRebatesNamesAtom = atom<string[]>({
  key: 'userClientRebatesNamesAtom',
  default: DEFAULT_APPLIED_REBATES,
  effects: [
    clientStorageEffect({
      isLocalStorage: true,
      key: USER_REBATES_STORAGE_KEY,
      validate: (value) => (Array.isArray(value) ? value : new DefaultValue()),
    }),
  ],
});

/**
 * This atom is a storage for questions for all possible makes we might support.
 * It will never update on consumer side in real time. Use it to render
 * full list of auction questions. Don't forget to filter by make id
 */
export const questionsAtom = atom<Question[]>({
  key: 'ATOM_QUESTIONS',
  default: selector({
    key: 'SELECTOR_QUESTIONS',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: () => rest.getQuestions(),
  }),
});

/**
 * This atom is a map between questions and rebates.
 * The original data is managed by leasebandit admin. It will never update
 * in real time on consumer side. Update it every time when admin creating
 * a new connection between question and rebate on admin panel
 */
export const rebatesQuestionsAtom = atom<RebateQuestion[]>({
  key: 'REBATES_QUESTIONS_ATOM',
  default: selector({
    key: 'REBATES_QUESTIONS_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: () => rest.getRebatesQuestions(),
  }),
});

export const rebatesAtom = atom<Rebate[]>({
  key: 'REBATES_ATOM',
  default: undefined,
});

export const forwardToDialogAtom = atom<ForwardDialogArgs>({
  key: 'FORWARD_TO_DIALOG_ATOM',
  default: {
    open: false,
    content: undefined,
    title: '',
    submitText: '',
  },
});

export const preventMultipleWinningBidDialogAtom = atom<{ open: boolean }>({
  key: 'PREVENT_MULTIPLE_WINNING_BID_DIALOG',
  default: { open: false },
});

export const userWinningBidIdAtom = atom<number>({
  key: 'USER_WINNING_BID_ID',
  default: undefined,
});

export const auctionIdsUserIsWatchingAtom = atom<number[]>({
  key: 'AUCTION_IDS_USER_IS_WATCHING_ATOM',
  default: selector({
    key: 'AUCTION_IDS_USER_IS_WATCHING_ATOM/DEFAULT_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: ({ get }) => {
      const user = get(userAtom);

      if (!user) {
        return;
      }

      return rest.getAuctionIdsUserIsWatching();
    },
  }),
});

export const auctionVisitorsCountAtomFamily = atomFamily<number, number>({
  key: 'AUCTION_VISITORS_COUNT_ATOM',
  default: selectorFamily({
    key: 'AUCTION_VISITORS_COUNT_ATOM/DEFAULT_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: (auctionId) => async () => {
      if (!auctionId) {
        return;
      }
      return (await rest.getAuctionVisitsCount(auctionId)).visitsCount;
    },
  }),
});

export const auctionWatchersCountAtomFamily = atomFamily<number, number>({
  key: 'AUCTION_WATCHERS_COUNT_ATOM',
  default: selectorFamily({
    key: 'AUCTION_WATCHERS_COUNT_ATOM/DEFAULT_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: (auctionId) => async () => {
      if (!auctionId) {
        return;
      }
      return (await rest.getAuctionWatchersCount(auctionId)).watchersCount;
    },
  }),
});

const DEFAULT_COOKIES_SETTINGS_VALUE = { accepted: false };
/**
 * This is the cookies settings atom.
 * Subscribe to it in case you need to get/set user cookies settings.
 */
export const cookiesSettingsAtom = atom<{ accepted: boolean }>({
  key: 'COOKIES_SETTINGS_ATOM',
  default: DEFAULT_COOKIES_SETTINGS_VALUE,
  effects: [
    clientStorageEffect({
      key: COOKIES_SETTINGS_STORAGE_KEY,
      validate: (value) =>
        typeof value?.accepted === 'boolean' ? value : new DefaultValue(),
    }),
  ],
});

export const msRebatesCategories = atom<MSISRebateCategory[]>({
  key: 'MS_REBATES_CATEGORIES_ATOM',
  default: selector({
    key: 'REBATE_CATEGORY_ATOM/DEFAULT',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: async () => {
      return rest.getMsRebatesCategories();
    },
  }),
});

export const rebatesCategoriesAtom = atom<MSAPIRequestRebateCategory[]>({
  key: 'REBATES_CATEGORIES_ATOM',
  default: selector({
    key: 'REBATES_CATEGORIES_ATOM/DEFAULT',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: ({ get }) => {
      const msRebatesCategoriesAtom = get(msRebatesCategories);
      const rebatesCategoriesAnswers = get(
        userRebatesCategoriesAnswersSelector,
      );
      return getRebatesCategoriesFromAnswersUsingQuestions(
        rebatesCategoriesQuestions,
        rebatesCategoriesAnswers,
        msRebatesCategoriesAtom,
      );
    },
  }),
});

export const isMobileNavOpenAtom = atom({
  key: 'IS_MOBILE_NAV_OPEN_ATOM',
  default: false,
});

export const getGoogleReviewsAtom = atom<GetGoogleReviewsResponse>({
  key: 'GET_GOOGLE_REVIEWS',
  default: [],
});

export const isBestDealAtomFamily = atomFamily<boolean, number>({
  key: 'IS_BEST_DEAL_ATOM_FAMILY',
  default: false,
});

export const getFutureByListedAuctionsAtom =
  atom<GetFutureByListedAuctionsResponse>({
    key: 'FUTURE_BY_LISTED_AUCTIONS',
    default: selector({
      key: 'FUTURE_BY_LISTED_AUCTIONS/DEFAULT',
      cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
      get: async () => {
        return rest.getFutureByListedAuctions();
      },
    }),
  });

export const importInventoryErrorsAtom = atom<null | any[]>({
  key: 'IMPORT_INVENTORY_ERRORS_ATOM',
  default: null,
});

export const importInventoryRateAtom = atom<null | [number, number]>({
  key: 'IMPORT_INVENTORY_RATE_ATOM',
  default: null,
});

export const openImportInventoryErrorsDialogAtom = atom<boolean>({
  key: 'OPEN_IMPORT_INVENTORY_ERRORS_DIALOG_ATOM',
  default: false,
});

export const isProgramAvailableAtom = atomFamily<boolean, number>({
  key: 'IS_PROGRAM_AVAILABLE_ATOM',
  default: true,
});

export const showUpdateLeaseTermsAtom = atom<boolean>({
  key: 'SHOW_UPDATE_LEASE_TERMS_ATOM',
  default: false,
});

export const msMakesAtom = atom<MSAPIMake[]>({
  key: 'MS_MAKES_ATOM',
  default: selector({
    key: 'MS_MAKES_ATOM/DEFAULT',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: () => {
      return rest.getMarketScanMakes();
    },
  }),
});

export const msModelsAtom = atom<MSAPIModel[]>({
  key: 'MS_MODELS_ATOM',
  default: selector({
    key: 'MS_MODELS_ATOM/DEFAULT',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: () => {
      return rest.getMarketScanModels();
    },
  }),
});

export const msModelYearsAtomFamily = atomFamily<
  MSAPIYear[],
  GetMarketScanModelYearsRequest
>({
  key: 'MS_MODEL_YEARS_ATOM',
  default: selectorFamily({
    key: 'MS_MODEL_YEARS_ATOM/DEFAULT_SELECTOR',
    cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
    get: (params) => async () => {
      if (
        !isFinite(Number(params.makeId)) ||
        !isFinite(Number(params.modelId))
      ) {
        return [];
      }
      return rest.getMarketScanModelYears(params);
    },
  }),
});

export const visitedAuctionsIDs = atom<number[] /* IDs */>({
  key: 'AUCTION_VISIT_ID',
  default: [],
  effects: [
    clientStorageEffect({
      isLocalStorage: true,
      key: 'user_visited_auctions_ids',
      validate: (value) => (Array.isArray(value) ? value : new DefaultValue()),
    }),
  ],
});

export const signUpPopupOpenAtom = atom<boolean>({
  key: 'SIGNUP_POPUP_OPEN_ATOM',
  default: false,
});

export const signUpPopupEnabledAtom = atom<boolean>({
  key: 'SIGNUP_POPUP_ENABLED_ATOM',
  default: false,
});

export const cookieStringAtom = atom<string>({
  key: 'COOKIE_STRING_ATOM',
  default: '',
});

export const confirmationDialogOpenStateAtom = atom<boolean>({
  key: 'CONFIRMATION_DIALOG_OPEN_STATE_ATOM',
  default: false,
});

export const rebatesCategoriesDialogAtom = atom<RebatesCategoriesDialogState>({
  key: 'REBATES_CATEGORIES_DIALOG_ATOM',
  default: {
    isOpen: false,
    answers: defaultRebatesCategoriesAnswers,
    title: 'Unlock additional savings by answering a few questions',
    vehicleOwn: [],
  },
});

export const redirectBannerOpenStateAtom = atom<boolean>({
  key: 'REDIRECT_BANNER_OPEN_STATE_ATOM',
  default: false,
});

export const donateBannerOpenStateAtom = atom<boolean>({
  key: 'DONATE_BANNER_OPEN_STATE_ATOM',
  default: true,
});

const { persistAtom: sessionPersistAtom } = recoilPersist({
  key: 'recoil_session_storage',
  storage: typeof window !== 'undefined' && sessionStorage,
});

export const bookACallDrawerOpen = atom<boolean>({
  key: 'BOOK_A_CALL_DRAWER_OPEN',
  default: false,
});

export const bookACallDrawerOpenedThisSession = atom<boolean>({
  key: 'BOOK_A_CALL_DRAWER_OPENED_THIS_SESSION',
  default: false,
  effects: [sessionPersistAtom],
});

export const identityPhoneAtom = atom<string>({
  key: 'IDENTITY_PHONE_ATOM',
  default: 'loading',
});

export const identityAtom = atom<Identity | undefined>({
  key: 'IDENTITY_ATOM',
  default: undefined,
});
