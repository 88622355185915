import localFont from 'next/font/local';
import { Roboto, Caveat, Lobster } from 'next/font/google';

export const Futura = {
  fontFamily: 'Futura',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url('/fonts/FuturaBT-Book.otf') format('opentype')`,
  fontDisplay: 'swap',
};

export const FuturaItalic = {
  ...Futura,
  fontStyle: 'italic',
  src: `url('/fonts/FuturaBT-BookItalic.otf') format('opentype')`,
};

export const FuturaBold = {
  ...Futura,
  fontWeight: 700,
  src: `url('/fonts/FuturaBT-Medium.otf') format('opentype')`,
};

export const nextFutura = localFont({
  src: [
    {
      path: '../public/fonts/FuturaBT-Book.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/FuturaBT-BookItalic.otf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../public/fonts/FuturaBT-Medium.otf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
});

export const nextRoboto = Roboto({
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
  preload: false,
});

export const nextCaveat = Caveat({
  subsets: ['latin'],
  display: 'swap',
  preload: false,
});

export const nextLobster = Lobster({
  weight: '400',
  subsets: ['latin'],
  display: 'swap',
  preload: false,
});
