import { atom, selectorFamily } from 'recoil';
import Rest from 'services/rest';
import { clientStorageEffect } from '../../../recoil/effects';
import { SAFE_RECOIL_CACHE } from 'recoil/safeRecoilCache';

const rest = new Rest();

export const zipcodeValidatorSelectorFamily = selectorFamily<
  { isValid: boolean },
  string
>({
  key: 'zipcodeValidatorSelectorFamily',
  cachePolicy_UNSTABLE: SAFE_RECOIL_CACHE,
  get: (zipcode: string) => async () => {
    return rest.validateZipcode(zipcode);
  },
});

export const filterZipcodeAtom = atom<string>({
  key: 'FILTER_ZIP_CODE_ATOM',
  default: '',
  effects: [
    clientStorageEffect({
      key: 'zipcode',
      validate: (value) => value,
    }),
  ],
});
