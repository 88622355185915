import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { ThemeProvider } from '@mui/material/styles';
import 'cross-fetch/polyfill';
import { AppProps } from 'next/app';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { lightThemeMui5 } from '../theme';

import 'odometer/themes/odometer-theme-minimal.css';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../styles/app.css';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { DefaultAppHead } from 'containers/App/DefaultAppHead';
import { FixRefLeasebanditHeadMetaCanonical } from 'containers/App/FixRefLeasebanditHeadMetaCanonical';
import { PageTransition } from 'containers/App/PageTransition';
import { WithRecoilRoot } from 'containers/App/WithRecoilRoot';
import { useDatadogRum } from 'hooks/useDatadogRum';
import { useFBTrackPageView } from 'hooks/useFBTrackPageView';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useHotjarMobileExitIntent } from 'hooks/useHotjarMobileExitIntent';
import { StrongPersistUnathorisedUserId } from 'hooks/useInitUnathorisedUserId';
import { useInitUser } from 'hooks/useInitUser';
import { useLogRocket } from 'hooks/useLogRocket';
import { NextPage } from 'next';
import { growthbook } from 'services/growthbook';
import { TimeTick } from '../components/atoms/TimeTick';
import ErrorBoundary from '../containers/error/ErrorBoundary';
import { createEmotionCache } from '../utils/createEmotionCache';

export type MyAppProps = AppProps & {
  Component: NextPage;
  emotionCache?: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const MyApp: FC<MyAppProps> = ({
  emotionCache = clientSideEmotionCache,
  pageProps,
  Component,
  ...props
}) => {
  useFBTrackPageView();
  useLogRocket();
  useDatadogRum();
  useInitUser(pageProps.user);
  useHotjarMobileExitIntent();
  useFeatureFlag(pageProps.user);

  // useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   const jssStyles = document.querySelector('#jss-server-side');
  //   if (jssStyles) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  return (
    // FIXME: play with types for ssr styles cache
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore emotionCache is not a valid prop for CacheProvider
    <CacheProvider value={emotionCache}>
      <GrowthBookProvider growthbook={growthbook}>
        <DefaultAppHead />
        <FixRefLeasebanditHeadMetaCanonical router={props.router} />
        <ThemeProvider theme={lightThemeMui5}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <WithRecoilRoot router={props.router} pageProps={pageProps}>
            <StrongPersistUnathorisedUserId />
            <PageTransition />
            <TimeTick />
            <SnackbarProvider>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </SnackbarProvider>
          </WithRecoilRoot>
        </ThemeProvider>
      </GrowthBookProvider>
    </CacheProvider>
  );
};

export default MyApp;
