import { CachePolicyWithoutEquality } from 'recoil';

/**
 * A cache policy configuration for Recoil selectors that ensures safe usage
 * in both server-side rendering (SSR) and client-side environments.
 *
 * This configuration uses a Least Recently Used (LRU) eviction policy and
 * adjusts the maximum cache size based on the environment:
 * - For SSR, the cache size is minimized to 1 to reduce memory usage.
 * - For client-side environments, the cache size is set to 100.
 *
 * @see {@link https://recoiljs.org/docs/api-reference/core/selector#cache-policy-configuration}
 */
export const SAFE_RECOIL_CACHE: CachePolicyWithoutEquality = {
  eviction: 'lru',
  // minimize the cache size for SSR
  maxSize: typeof window === 'undefined' ? 1 : 100,
};
